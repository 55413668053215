.header {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 3.2rem;
  background-color: #2dbecd;
  max-height: 4rem;
  width:100%;
}
.Menu_Icon {
  display: none;
}
.label-wrapper {
  display: inline-block;
  font-size: 12px;
  line-height: 1.25;
  letter-spacing: 0.2px;
  color: green;
}
.navigation-bar {
  width: 100%;
  display: flex;
  padding: 0;
  margin: 0;
  color: white;
}
ul li {
  display: inline;
}
li {
  flex-basis: 0;
}

.milliq-trademark {
  flex: 4 1;
  max-width: 198px;
  padding-left: 1%;
}
.news-marquee {
  flex: 8 1;
  border-radius: 21px;
  background: white;
  color: #2dbecd;
  height: 32px;
  text-align: center;
  font-size: 1rem;
  //padding: 7px;
  border: 1px solid white;
  font-weight: 700;
  margin-left: 28px;
  width: 57%;
  .marquee {
    width: auto;
    line-height: 30px;
    white-space: nowrap;
    overflow: hidden;
    box-sizing: border-box;
}
.marquee .marquee-item {
    display: inline-block;
    padding-left: 100%;
    animation: marquee 40s linear infinite;
}

@keyframes marquee {
    0%   { transform: translate(0, 0); }
    100% { transform: translate(-100%, 0); }
}
}
.news-marquee._marquee {
  display: none;
}
.marquee .marquee-item:hover { 
  -moz-animation-play-state: paused;
  -webkit-animation-play-state: paused;
  animation-play-state: paused;
}
.rating {
  flex: 1;
  max-width: 40px;
  margin-left: 5px;
  margin-top: 4px;
  cursor: pointer;
}
.user-profile {
  flex: 2;
  display: flex;

}
.milliq-text-h4 {
  font-size: 1.4rem;
  margin: 0;
  letter-spacing: 2px;
  color: white;
}
.trademark-text {
  font-weight: 700;
  font-size: 0.8rem;
}
.star-icon {
  width: 24px;
  height: 27px;
  margin-left: 20%;
  align-items: center;
}
.user-profile-icon {
  width: 100%;
  border-radius: 50%;
}
.user-icon-div {
  flex: 1 1;
  background-color: white;
  border-radius: 50%;
  max-width: 2rem;
  margin-left: 3%;
  margin-right: 4%;
  display: flex;
  justify-content: center;
}
.cabab-menu {
  width: 20px;
  height: 25px;
  background-image: radial-gradient(circle, white 2px, transparent 3px);
  background-size: 100% 33.33%;
  margin-top:5px;
}
.ant-dropdown {
  ul {
    text-align: center;
  }
}
.ant-dropdown-menu {
  padding: 10px;
  border-radius: 8px !important;
}
.trademarks h3 {
  letter-spacing: 1px;
  color: #2dbecd;
  font-size: 1.3rem;
  margin-top: 5px;
}

.trademarks-flex {
  display: flex;
  flex-direction: column;
}

.trade-mark {
  font-size: 0.6rem;
  font-weight: 600;
}
.ant-dropdown-menu-item:hover {
  background-color: white !important;
  cursor: text;
}
.hr-break {
  border-top: 1px solid #bbb;
  margin: -6% 15% -3%;
}
.sub-heading-tm {
  font-weight: 500 !important;
  font-size: 0.6rem !important;
}
.user-name-header {
  width: 200px !important;
  overflow: hidden;
  text-overflow: ellipsis;
}
.rating_summary {
  .ant-card-body {
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    ul {
      font-size: 18px;
      margin: 0;
      padding: 0;
    }
  }
  .average_rating {
    font-size: 2em;
    font-weight: 500;
    padding: 0;
    margin: 0;
  }
  .feedback-ratings {
    margin: 0;
    margin-top: 0.3rem;
    display: flex;
    justify-content: center;
    align-items: center;
    svg {
      height: 1.2rem;
    }
  }
  .user_feedback__rate {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    [class~='ant-rate-star-full'] div {
      color: #388e3c !important;
    }
  }
}
.star_rating_dropdown {
  top: 2.2rem !important;
  .rating_summary {
    width: 12rem;
    display: flex;
    height: 3.5rem;
    justify-content: center;
    align-items: center;
    margin-top: -0.5rem;
  }
  .ant-card-bordered {
    border: none;
  }
  ul.ant-dropdown-menu.ant-dropdown-menu-light.ant-dropdown-menu-root.ant-dropdown-menu-vertical {
    height: 4rem;
  }
}
.ant-dropdown-menu-item:hover {
  background: none !important;
}
.no-feeback {
  margin-top: -0.6rem;
}
.rating_by_user {
  margin-left: 0.5rem !important;
  margin-bottom: 0.2rem !important;
}

.ant-dropdown-menu-item {
  &.menuItem {
    justify-content: flex-start;
    flex-direction: column;
    align-items: flex-start;
  }
}
.dropdown-manage-profile {
  display: flex;
  cursor: pointer;
  &:hover {
    color:#2dbecd;
  }
  .menuItemText {
    padding: 0 5px;
  }
}
/* Extra small devices (phones, 600px and down) */
// @media only screen and (max-width: 600px) {}

/* Small devices (portrait tablets and large phones, 600px and up) */
// @media only screen and (min-width: 600px) {}

/* Medium devices (landscape tablets, 768px and up) */
// @media only screen and (min-width: 768px) {...}

/* Large devices (laptops/desktops, 992px and up) */
// @media only screen and (min-width: 992px) {...}

/* Extra large devices (large laptops and desktops, 1200px and up) */
// @media only screen and (min-width: 992px) {}
.news-feed-carousel{
  .ant-modal-content{
    display: block;
  }
}

/** Media Query **/
@media screen and (min-width: 907px) and (max-width: 1127px) {
  .news-marquee {
    flex: 4;
  }
}

@media screen and (max-width: 900px) {
  .navigation-bar {
      height: 26px;
  }
  .milliq-trademark {
      flex: 3;
      width: 100%;
      max-width: 100%;
  }
  .user-profile-icon {
      width: 22px;
      padding-top: 3px;
      height: 20px;
      margin-left: 0.3em;
  }
   
}

// *********************** Responsive Design **********************//
// 0-767 - mobile ****768-1024 -ipad *****1025-above All web********//
@media screen and (min-width: 768px) and (max-width: 1024px) {
  .news-marquee {
    display: none;
}
.news-marquee._marquee {  
  position: fixed;
  display: inline-block;
  margin-left: 0px;
  width: 100%;
  height: 40px;
  fill: #ffffff;
  box-shadow: 3px 2px 49px #00000014, 0px 2px 4px #00000014;
  border-radius: 0px;
}
.navigation-bar {
    height: 26px;
}
.milliq-trademark {
    flex: 9 0;
    width: 100%;
    max-width: 100%;
}
.user-profile-icon {
    width: 22px;
    padding-top: 3px;
    height: 20px;
    margin: 2px;
}
  .Menu_Icon {
    display: inline;
     margin: 0px 0 0 15px !important;
 }
 .Menu_Icon {
     margin: 0;
     padding: 0;
     width: 100%;
     background-color: #2dbecd;
     color: #fff;
     // box-shadow: 0 1px 2px 0 #bdbbbd;
 }
 .side-bar-container {
     display:flex;
 }  
 

 .ant-col-21 {
     display: block;        
     max-width: 100%;
 }
 .side-bar-container {     
     margin-top: 0rem;
     z-index: 100;
     position: absolute !important;
 }
 aside.ant-layout-sider.ant-layout-sider-dark.ant-layout-sider-collapsed.side-bar-box {
  display: inline-block;
  left: -80px !important;
  transition:all 0.3s !important;
 }
.expand-div { 
 cursor: pointer;
 background-color: transparent;
 padding: 3px;
 border-bottom-right-radius: 4px;
 border-top-right-radius: 3px;
}

.margin-change {
 margin-left: 0px;
}
.rating {
  flex: 1 1;
  max-width: 40px;
  margin-left: 0px;
  margin-top: 0px;
  cursor: pointer;
}
.milliq-text-h4 { 
  font-size: 1.2rem;
}
.rating-div{
  font-size: 1.2rem !important;  
    margin: 0px 5px 0px 0px;
}
.cabab-menu {
  width: 25px;
  height: 20px;
}
}
/************************************* Mobile ***********************************/
@media screen and (max-width: 767px) {
  .news-marquee {
    display: none;
}
.news-marquee._marquee {  
  position: fixed;
  display: inline-block;
  margin-left: 0px;
  width: 100%;
  height: 40px;
  fill: #ffffff;
  box-shadow: 3px 2px 49px #00000014, 0px 2px 4px #00000014;
  border-radius: 0px;
}
.navigation-bar {
    height: 26px;
}
.milliq-trademark {
    flex: 3;
    width: 100%;
    max-width: 100%;
}


.user-profile {
  flex: 0 1;
  display: flex;

  &-headername {
    display: none;
  }
  &-icon {
    width: 25px;
    padding-top: 3px;
    height: 20px;
    margin-left: 0em;
  }
}
.Menu_Icon {
  display: inline;
   margin: 0px 0 0 15px !important;
}
.Menu_Icon {
   margin: 0;
   padding: 0;
   width: 100%;
   background-color: #2dbecd;
   color: #fff;
   // box-shadow: 0 1px 2px 0 #bdbbbd;
}
.side-bar-container {
   display:flex;
}  


.ant-col-21 {
   display: block;        
   max-width: 100%;
}
.side-bar-container { 
   margin-top: 0rem;
   z-index: 100;
   position: absolute !important;
}
aside.ant-layout-sider.ant-layout-sider-dark.ant-layout-sider-collapsed.side-bar-box {
  display: inline-block;
  left: -80px !important;
  transition:all 0.3s !important;
 }
.expand-div { 
cursor: pointer;
background-color: transparent;
padding: 3px;
border-bottom-right-radius: 4px;
border-top-right-radius: 3px;
}
.margin-change {
margin-left: 0px;
}
.rating {
  flex: 1 1;
  max-width: 40px;
  margin-left: 0px;
  margin-top: 0px;
  cursor: pointer;
}
.milliq-text-h4 { 
  font-size: 1.2rem;
}
.rating-div{
  font-size: 1.2rem !important;  
    margin: 0px 5px 0px 0px;
}
.cabab-menu {
  width: 25px;
  height: 20px;
}
}
